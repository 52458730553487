import { useEffect, useState } from "react";
import { Container, Typography, Grid, Box } from "@mui/material";
import { Link } from "react-router-dom";
import { useReportStore } from "../../data/ReportStore";
import ReportPicker from "../Elements/ReportPicker";

import { getUpcomingGames, getGameLog } from "../../util/Requests";
import PastGame from "../Elements/GameDisplay/PastGame";
import UpcomingGame from "../Elements/GameDisplay/UpcomingGame";
import GamesSkeleton from "../Elements/GameDisplay/GamesSkeleton";
import { Game, GameWithID } from "../../models/Game";

export default function Home() {
  const { setTeam, setReportType } = useReportStore((store) => store);
  const [upcomingGames, setUpcomingGames] = useState<Game[] | null>(null);
  const [prevGames, setPrevGames] = useState<GameWithID[] | null>(null);

  useEffect(() => {
    setTeam(null);
    setReportType(null);

    getUpcomingGames()
      .then(({ data }) => {
        setUpcomingGames(data);
      })
      .catch((err) => {
        console.log("error getting upcoming games: ", err);
      });
    getGameLog(5).then(({ data }) => {
      const prevGamesFormatted = data.map(
        (gameTuple) =>
          ({
            id: gameTuple[0],
            date: gameTuple[1],
            opponentInfo: gameTuple[2],
          } as GameWithID)
      );
      setPrevGames(prevGamesFormatted);
    });
  }, [setTeam, setReportType]);

  return (
    <>
      <ReportPicker collapseByDefault />
      <Container maxWidth="xl">
        <Grid container mt={4} spacing={4}>
          <Grid item xs={12} lg={6}>
            <Typography variant="h2" mb={4}>
              Recent games
            </Typography>
            {prevGames === null ? (
              <GamesSkeleton num={5} />
            ) : prevGames.length === 0 ? (
              <Box
                pb={4}
                pt={3.5}
                px={6}
                mb={2}
                sx={{
                  border: "2px solid black",
                  borderRadius: "15px",
                  backgroundColor: "white",
                }}
              >
                <Typography variant="h3">No recent game reports</Typography>
              </Box>
            ) : (
              prevGames.map((game) => (
                <PastGame
                  game={game}
                  key={`${game.date}:${game.opponentInfo}}`}
                />
              ))
            )}
            {prevGames !== null && prevGames.length === 5 ? (
              <Box display="flex" justifyContent="center">
                <Link to="/games">See full game log</Link>
              </Box>
            ) : null}
          </Grid>
          <Grid item xs={12} lg={6}>
            <Typography variant="h2" mb={4}>
              Upcoming games
            </Typography>
            {upcomingGames === null ? (
              <GamesSkeleton num={5} />
            ) : upcomingGames.length === 0 ? (
              <Box
                pb={4}
                pt={3.5}
                px={6}
                mb={2}
                sx={{
                  border: "2px solid black",
                  borderRadius: "15px",
                  backgroundColor: "white",
                }}
              >
                <Typography variant="h3">No upcoming games</Typography>
              </Box>
            ) : (
              upcomingGames.map((game) => (
                <UpcomingGame
                  game={game}
                  key={`${game.date}:${game.opponentInfo}}`}
                />
              ))
            )}
          </Grid>
        </Grid>
      </Container>
    </>
  );
}
